<template>
  <div class="overlay">
    <page-title>{{ i18n.CONFIG.TITLE }}</page-title>
    <div class="tools">
      <button
        class="tools__item"
        v-if="isAuthenticated"
        @click.prevent="saveConfig"
      >
        <img class="tools__img" :src="getConfig('1.svg')" />
        <div class="tools__text">{{ i18n.CONFIG.SAVE }}</div>
      </button>
      <!--      <button class="tools__item">-->
      <!--        <img class="tools__img" :src="getConfig('2.svg')">-->
      <!--        <div class="tools__text">Надіслати посилання</div>-->
      <!--      </button>-->
      <button class="tools__item" @click.prevent="getPDF(false)">
        <img class="tools__img" :src="getConfig('3.svg')" />
        <div class="tools__text">{{ i18n.CONFIG.DOWNLOAD }}</div>
      </button>
      <div class="row">
        <div class="col">
          <copy-to-clipboard
            :code="code"
            @success="showMessage"
            @error="showMessage"
          />
        </div>
      </div>
      <div class="row">
        <div class="col text-page">
          <div v-html="configText" />
          <img
            :src="
              require(`@/assets/images/sandwich-${this.lang.substr(0, 2)}.svg`)
            "
            alt=""
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import PageTitle from '@/components/page-title/page-title.vue';
import CopyToClipboard from '@/components/shared/copy/copy-to-clipboard';
import { mapGetters } from 'vuex';
import axios from 'axios';
import { API } from '@/constants/api';
import { drawPNG } from '@/utils/picture';
import { formatCode } from '@/utils/code';

export default {
  components: {
    PageTitle,
    CopyToClipboard
  },
  computed: {
    ...mapGetters('session', ['i18n', 'lang']),
    ...mapGetters('config', [
      'shape',
      'model',
      'glass',
      'color',
      'furniture',
      'options',
      'safeglass',
      'inox',
      'view',
      'code',
      'house',
      'mirrored',
      'black'
    ]),
    ...mapGetters('auth', ['isAuthenticated']),
    formattedCode() {
      return formatCode(this.code);
    },
    configText() {
      return this.i18n.CONFIG.TEXT;
    }
  },
  metaInfo() {
    return {
      title: this.i18n.MENU.config
    };
  },
  // metaInfo: {
  //   title: this.i18n.CONFIG.META.TITLE,
  //   meta: [
  //     { vmid: 'description', property: 'description', content: this.i18n.CONFIG.META.DESCRIPTION },
  //     { vmid: 'og:title', property: 'og:title', content: this.i18n.CONFIG.META.OG_TITLE },
  //     { vmid: 'og:description', property: 'og:description', content: this.i18n.CONFIG.META.OG_DESCRIPTION },
  //   ],
  // },
  methods: {
    getConfig(pic) {
      return require('@/assets/images/config/' + pic);
    },
    showMessage(text) {
      this.$swal({
        title: this.i18n.INFO_TITLE,
        text: this.i18n.CONFIG.CODE_SHARE(text),
        width: 375,
        confirmButtonText: 'OK',
        confirmButtonColor: '#C70552',
        customClass: {
          header: 'sweet-modal__header',
          title: 'sweet-modal__title',
          popup: 'sweet-modal__popup',
          content: 'sweet-modal__content',
          actions: 'sweet-modal__actions',
          confirmButton: 'sweet-modal__ok-button'
        }
      });
    },
    async saveConfig() {
      const { code } = this;

      let response;
      let base64PNG1;

      try {
        const svgImage1 = document.querySelector(
          'svg.configurator__door--outside'
        );
        const svgImage1Box = svgImage1.getBBox();
        const svgWidth = parseInt(svgImage1Box.width, 10);
        const svgHeight = parseInt(svgImage1Box.height, 10);
        const serializer1 = new XMLSerializer().serializeToString(svgImage1);
        const base64String1 =
          'data:image/svg+xml;base64,' +
          window.btoa(unescape(encodeURIComponent(serializer1)));

        base64PNG1 = await drawPNG({
          svg: base64String1,
          flip: this.mirrored?.mirrored_type === 'mirrored',
          width: svgWidth,
          height: svgHeight
        });
      } catch (e) {
        console.error(e);
      }

      try {
        response = await axios({
          method: 'post',
          url: API.ADD_DOOR,
          data: {
            code,
            door: base64PNG1
          }
        });
      } catch (e) {
        await this.$swal({
          title: this.i18n.ERROR_TITLE,
          text: 'Помилка додавання до списку "Мої двері"',
          width: 375,
          confirmButtonText: 'OK',
          confirmButtonColor: '#C70552',
          customClass: {
            header: 'sweet-modal__header',
            title: 'sweet-modal__title',
            popup: 'sweet-modal__popup',
            content: 'sweet-modal__content',
            actions: 'sweet-modal__actions',
            confirmButton: 'sweet-modal__ok-button'
          }
        });
      }
      if (response.data.success) {
        await this.$swal({
          title: this.i18n.INFO_TITLE,
          text: this.i18n.CONFIG.ADDED_TO_MY_DOORS,
          width: 375,
          confirmButtonText: 'OK',
          confirmButtonColor: '#C70552',
          customClass: {
            header: 'sweet-modal__header',
            title: 'sweet-modal__title',
            popup: 'sweet-modal__popup',
            content: 'sweet-modal__content',
            actions: 'sweet-modal__actions',
            confirmButton: 'sweet-modal__ok-button'
          }
        });
      }
    },
    async getPDF(second) {
      let side;
      if (this.black === 'none') {
        side = this.color.type === 'complete' ? 'outside' : this.color.type;
      } else {
        side = this.black === 'both' ? 'outside' : this.black;
      }
      await this.$store.dispatch('config/CONFIG_SET_HOUSE_VIEW', side);
      const { code } = this;

      const svgImage1 = document.querySelector(
        'svg.configurator__door--outside'
      );
      const svgImage2 = document.querySelector(
        'svg.configurator__door--inside'
      );
      const svgImage1Box = svgImage1.getBBox();
      const svgWidth = parseInt(svgImage1Box.width, 10);
      const svgHeight = parseInt(svgImage1Box.height, 10);
      const serializer1 = await new XMLSerializer().serializeToString(
        svgImage1
      );
      const serializer2 = await new XMLSerializer().serializeToString(
        svgImage2
      );
      const base64String1 =
        'data:image/svg+xml;base64,' +
        window.btoa(unescape(encodeURIComponent(serializer1)));
      const base64String2 =
        'data:image/svg+xml;base64,' +
        window.btoa(unescape(encodeURIComponent(serializer2)));

      let base64PNG1;
      let base64PNG2;
      const isFlip = this.mirrored?.mirrored_type === 'mirrored' || false;

      try {
        base64PNG1 = await drawPNG({
          svg: base64String1,
          width: svgWidth,
          height: svgHeight,
          flip: isFlip
        });
      } catch (e) {
        console.error(e);
      }

      try {
        base64PNG2 = await drawPNG({
          svg: base64String2,
          width: svgWidth,
          height: svgHeight,
          flip: !isFlip
        });
      } catch (e) {
        console.error(e);
      }

      if (!base64PNG1 || !base64PNG2) {
        return;
      }

      await this.$nextTick();

      axios({
        method: 'post',
        url: API.GET_PDF,
        responseType: 'arraybuffer',
        data: {
          code,
          width: svgWidth,
          height: svgHeight,
          door: {
            outside: base64PNG1,
            inside: base64PNG2
          }
        }
      }).then(async response => {
        const blob = await new Blob([response.data], {
          type: 'application/pdf'
        });
        let link = document.createElement('a');
        link.href = window.URL.createObjectURL(blob);
        link.download = 'MACKO_' + this.formattedCode + '.pdf';
        if (second) {
          link.click();
        } else {
          await this.$nextTick();
          this.getPDF(true);
        }
      });
    }
  }
};
</script>

<style lang="scss">
.tools {
  margin: 30px 30px 0;
  &__item {
    width: 100%;
    height: 46px;
    padding: 0 14px;
    cursor: pointer;
    font: 15px/18px 'ProximaNovaBold', sans-serif;
    text: {
      transform: uppercase;
      align: center;
    }
    color: #ffffff;
    display: flex;
    align-items: center;
    margin-top: 10px;
    background-color: #c70552;
    border-radius: 3px;
    border: none;
    &:first-child {
      margin-top: 0;
    }
    &:last-child {
      margin-top: 30px;
    }
  }
  &__img {
    display: inline-block;
    margin-right: 10px;
  }
  &__text {
    flex: 1;
  }
}
</style>
